<template>
  <div class="col-12">
    <div class="text-left" v-if="tipo == 'editar'">
      <v-btn color="success" @click="actualizarNombres" outlined>
        Actualizar nombres
      </v-btn>
    </div>
    <v-data-table
      :headers="headers"
      :items="listFiile"
      loading="true"
      :items-per-page="-1"
    >
      <template v-slot:item="row">
        <tr>
          <td v-if="tipo == 'editar'">
            <v-btn
              color="error"
              x-small
              fab
              @click="eliminarArchivo(row.item.id)"
            >
              <v-icon dense>mdi-delete</v-icon>
            </v-btn>
          </td>
          <td>
            <v-text-field
              v-model="row.item.name"
              :readonly="tipo != 'editar'"
            ></v-text-field>
          </td>
          <td>{{ row.item.fecha }}</td>
          <td>
            <a @click="verPdf(row.item.path)" target="_blank">
              <v-icon color="info">mdi-file</v-icon>
            </a>
          </td>
          <td>{{ row.item.type }}</td>
        </tr>
      </template>
      <!-- SI NO HAY REGISTROS -->
      <template v-slot:no-data> No se encontraron registros </template>
    </v-data-table>
    <v-dialog
      v-model="modalCargando"
      scrollable
      persistent
      :overlay="false"
      max-width="600px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title primary-title> Un momento por favor ... </v-card-title>

        <v-card-text>
          <v-progress-linear
            color="deep-purple accent-4"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions } from "vuex";
import axios from "axios";
import funcion from "@/mixins/funciones";
export default {
  name: "listaArchivosComponent",
  props: ["tipo"],
  mixins: [funcion],
  mounted() {
    if (this.tipo == "editar") {
      this.headers = [
        { text: "Acciones" },
        { text: "Nombre" },
        { text: "Fecha" },
        { text: "link" },
        { text: "Tipo" },
      ];
    } else {
      this.headers = [{ text: "Nombre" }, { text: "link" }, { text: "Tipo" }];
    }
  },
  data() {
    return {
      modalCargando: false,
      headers: [],
    };
  },
  methods: {
    ...mapMutations(["setListFiile"]),
    ...mapActions(["getFilesQuote", "deletePath"]),
    actualizarNombres() {
      this.modalCargando = true;
      var headers = {
        "auth-token": JSON.parse(localStorage.getItem("token")),
        "Content-Type": "application/json",
      };

      this.listFiile.forEach((element) => {
        var data = JSON.stringify({
          name: element.name,
          id_branch: JSON.parse(localStorage.getItem("branch")),
        });
        var config = {
          method: "post",
          url: process.env.VUE_APP_URL_MAIN + `putPath/` + element.id,
          headers: headers,
          data: data,
        };

        axios(config);
      });
      setInterval(() => {
        this.modalCargando = false;
      }, this.listFiile.length * 200);
    },
    verPdf(ruta) {
      window.open(ruta, "_blank");
    },
    eliminarArchivo(id) {
      this.deletePath(id);
      setTimeout(() => {
        this.getFilesQuote(this.$route.params.id);
      }, 500);
    },
    async obtenerNuevaLista() {
      this.getFilesQuote(this.$route.params.id);
    },
  },
  computed: {
    ...mapState(["listFiile", "actualizarListaArchivos"]),
  },
  watch: {
    actualizarListaArchivos() {
      this.obtenerNuevaLista();
    },
  },
};
</script>

<style></style>
